// src/hooks/useFormClient.ts
import { useState } from 'react';
import { cepInfosType, requestCEPInfos } from '../../services/viacep.service';
import { createClient } from '../../services/client.service'; // Importando o serviço
import { cepMask } from '../../utils/inputMasks/cepMask';
import { useNavigate } from 'react-router-dom';

interface Responsavel {
  nome: string;
  email: string;
  telefone: string;
  isPrincipal: boolean;
}

export interface FormClientState { // Exportando a interface para usar no serviço
  cnpj: string;
  razaoSocial: string;
  nomeFantasia: string;
  logradouro: string;
  cidade: string;
  estado: string;
  cep: string;
  bairro: string;
  endereco: string;
  numeroEmpresarial: string;
  telefone: string;
  complemento: string;
  celular: string;
  email: string;
  responsaveis: Responsavel[];
}

const useFormClient = () => {
  const navigate = useNavigate();
  const [formState, setFormState] = useState<FormClientState>({
    cnpj: '',
    razaoSocial: '',
    nomeFantasia: '',
    logradouro: '',
    cidade: '',
    estado: '',
    cep: '',
    bairro: '',
    complemento: '',
    endereco: '',
    numeroEmpresarial: '',
    telefone: '',
    celular: '',
    email: '',
    responsaveis: [
      { nome: '', email: '', telefone: '', isPrincipal: true },
    ],
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleResponsavelChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { id, value } = e.target;
    const newResponsaveis = [...formState.responsaveis];
    newResponsaveis[index] = { ...newResponsaveis[index], [id]: value };
    setFormState((prevState) => ({
      ...prevState,
      responsaveis: newResponsaveis,
    }));
  };

  const addResponsavel = () => {
    setFormState((prevState) => ({
      ...prevState,
      responsaveis: [...prevState.responsaveis, { nome: '', email: '', telefone: '', isPrincipal: false }],
    }));
  };

  const removeResponsavel = (index: number) => {
    const newResponsaveis = formState.responsaveis.filter((_, i) => i !== index);
    setFormState((prevState) => ({
      ...prevState,
      responsaveis: newResponsaveis,
    }));
  };

  const handleCepChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCep = e.target.value;
    const maskedCep = cepMask(newCep);

    setFormState((prevState) => ({
      ...prevState,
      cep: maskedCep,
    }));

    if (maskedCep.length === 9) {
      const cepInfos: cepInfosType | undefined = await requestCEPInfos(maskedCep);

      if (cepInfos) {
        setFormState((prevState) => ({
          ...prevState,
          endereco: cepInfos.logradouro,
          cidade: cepInfos.localidade,
          estado: cepInfos.uf,
          bairro: cepInfos.bairro,
        }));
      }
    }
  };

  const handleSubmit = async () => {
    try {
      await createClient(formState); // Usando o serviço para criar o cliente
      console.log('Cliente criado com sucesso!');
      navigate('/listclients'); // Redirecionar após a criação
    } catch (error) {
      console.error('Erro ao criar cliente:', error);
      // Tratar erros aqui (exibir mensagem de erro, etc.)
    }
  };

  const handleBackNav = () => {
    navigate('/listclients');
  };

  return {
    formState,
    handleChange,
    handleResponsavelChange,
    addResponsavel,
    removeResponsavel,
    handleCepChange,
    handleSubmit,
    handleBackNav,
  };
};

export default useFormClient;
