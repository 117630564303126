import { useState } from "react";
import TableComponent, { Column } from "../../components/Table/Table";
import LayoutDashboard from "../../layouts/LayoutDashboard/LayoutDashboard";

import { useNavigate } from "react-router-dom";
import './_listClients.scss';
import useListClients from "./useListClients";

export default function ListClients() {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const rowsPerPage = 7;

  const { clients, loading, error } = useListClients();

  const handleNavigate = () => {
    navigate('/registerclient')
  }




  const titleColumns: (string | { label: string; width?: string })[] = [
    { label: 'ID', width: '0%' },
    { label: 'CNPJ', width: '10%' },
    { label: 'Razão Social', width: '10%' },
    { label: 'Nome Fantasia', width: '10%' },
    { label: 'Telefone', width: '10%' },
    { label: 'Celular', width: '10%' },
    { label: 'Nome do responsavel', width: '10%' },
    { label: 'Email do responsavel', width: '10%' },
  ];


  const columns: Column[] = [
    {
      label: 'ID',
      format: (value, clients) => clients?.id || '-',
      width: '0%',
    },
    {
      label: 'CNPJ',
      format: (value, clients) => clients?.cnpj || '-',
      width: '10%',
    },
    {
      label: 'Razao Social',
      format: (value, clients) => clients?.razaoSocial || '-',
      width: '10%',
    },
    {
      label: 'Nome Fantasia',
      format: (value, clients) => clients?.nomeFantasia || '-',
      width: '10%',
    },
    {
      label: 'Telefone',
      format: (value, clients) => clients?.telefone || '-',
      width: '10%',
    },
    {
      label: 'Nome do responsável',
      format: (value, clients) =>
        clients?.responsaveis.find((r: { isPrincipal: boolean; }) => r.isPrincipal)?.telefone || '-', // Exibe o nome do responsável principal
      width: '10%',
    },
    {
      label: 'Nome do responsável',
      format: (value, clients) =>
        clients?.responsaveis.find((r: { isPrincipal: boolean; }) => r.isPrincipal)?.nome || '-', // Exibe o nome do responsável principal
      width: '10%',
    },
    {
      label: 'Email do responsável',
      format: (value, clients) =>
        clients?.responsaveis.find((r: { isPrincipal: boolean; }) => r.isPrincipal)?.email || '-', // Exibe o email do responsável principal
      width: '10%',
    },
  ];



  const heightTable = "19rem";
  const styleHeader = {
    color: "var(--Padro-texto-escuro, #333B3E)",
    fontFamily: "New Order",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    whiteSpace: "nowrap",
    borderTop: "1px solid #DFE4EC",
    borderBottom: "1px solid #DFE4EC",
    background: "#EDF1F5",
    textAlign: 'center',
    padding: '1rem 0.5rem 1rem 0.5rem'
  };
  const styleBody = {
    color: "#100F14",
    fontFamily: "New Order",
    fontSize: "0.75rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    cursor: "pointer",
    whiteSpace: "nowrap",
    padding: "1.2rem",
    textAlign: 'center',
  };

  const TableProps = {
    titleColumns,
    columns,
    dataTable: clients,
    page,
    setPage,
    rowsPerPage,
    totalPages,
    setTotalPages,
    totalItems: clients.length,
    styleHeader,
    styleBody,
    heightTable,
  };

  return (
    <LayoutDashboard>
      <div className="list-clients-container">
        <div className="list-clients-content">
          <div className="list-clients-content-header">
            <h2>Lista de clientes</h2>

            <button onClick={handleNavigate} className="button-system listclient">Cadastrar cliente </button>
          </div>

          <div className="list-clients-content-table">
            <TableComponent TableProps={TableProps} />
          </div>
        </div>
      </div>
    </LayoutDashboard>
  )
}