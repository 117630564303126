import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_BASEURL_API as string, // Certifique-se de que essa URL seja a correta
  // baseURL: 'http://localhost:3001/'
});

export const createConsultor = async (data: any) => {
  try {
    const response = await api.post('/consultores', data);
    return response.data;
  } catch (error) {
    console.error('Erro ao criar consultor:', error);
    throw error;
  }
};

export const getConsultores = async () => {
  try {
    const response = await api.get('/consultores');
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar consultores:', error);
    throw error;
  }
};

export const getConsultorById = async (id: string) => {
  try {
    const response = await api.get(`/consultores/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Erro ao buscar consultor com ID ${id}:`, error);
    throw error;
  }
};

export const updateConsultor = async (id: string, data: any) => {
  try {
    const response = await api.put(`/consultores/${id}`, data);
    return response.data;
  } catch (error) {
    console.error(`Erro ao atualizar consultor com ID ${id}:`, error);
    throw error;
  }
};

export const deleteConsultor = async (id: string) => {
  try {
    const response = await api.delete(`/consultores/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Erro ao deletar consultor com ID ${id}:`, error);
    throw error;
  }
};
