import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import ModalFeedback from '../ModalFeedback/ModalFeedback';
import './_formConsultores.scss';
import useFormConsultor from './useFormConsultores';

const FormConsultores = () => {
  const {
    formState,
    confirmEmailFas,
    confirmPassword,
    errors,
    modalOpen,
    modalSuccess,
    modalMessage,
    handleChange,
    handleConfirmEmailChange,
    handleConfirmPasswordChange,
    handleNestedChange,
    handleCnpjChange,
    handleCepChange,
    handleSubmit,
    handleBackNav,
    handleCloseModal,
    handleCheckboxChange,
  } = useFormConsultor();

  return (
    <div className="form-consultores-container">
      <div className="form-consultores-container-title">
        <h2>Cadastro de Consultores</h2>
      </div>

      <div className="form-consultores-container-body">
        {/* Seção: Dados Gerais */}
        <div className="form-consultores-section">
          <h3>Dados Gerais</h3>
          <div className='form-consultores-section-pessoafisica'>
            <div className='form-consultores-section-pessoafisica-left'>
              <TextField
                id="nomeCompleto"
                label="Nome Completo"
                variant="outlined"
                fullWidth
                value={formState.nomeCompleto}
                onChange={handleChange}
              />
              <TextField
                id="dataNascimento"
                label="Data de Nascimento"
                variant="outlined"
                fullWidth
                placeholder="dd/mm/yyyy"
                value={formState.dataNascimento}
                onChange={handleChange}
              />


              <TextField
                id="telefone"
                label="Telefone"
                variant="outlined"
                fullWidth
                value={formState.telefone}
                onChange={handleChange}
              />
              <TextField
                id="celular"
                label="Celular"
                variant="outlined"
                fullWidth
                value={formState.celular}
                onChange={handleChange}
              />
              <TextField
                id="emailParticular"
                label="E-mail Particular"
                variant="outlined"
                fullWidth
                value={formState.emailParticular}
                onChange={handleChange}
              />

            </div>
            <div className='form-consultores-section-pessoafisica-right'>
              <TextField
                id="cep"
                label="CEP"
                variant="outlined"
                fullWidth
                value={formState.address.cep}
                onChange={handleCepChange}
              />
              <TextField
                id="enderecoResidencial"
                label="Endereço Residencial"
                variant="outlined"
                fullWidth
                value={formState.address.enderecoResidencial}
                onChange={(e) => handleNestedChange('address', 'enderecoResidencial', e.target.value)}
              />
              <TextField
                id="bairro"
                label="Bairro"
                variant="outlined"
                fullWidth
                value={formState.address.bairro}
                onChange={(e) => handleNestedChange('address', 'bairro', e.target.value)}
              />
              <div style={{ display: 'flex', gap: '1rem' }}>
                <TextField
                  id="cidade"
                  label="Cidade"
                  variant="outlined"
                  fullWidth
                  value={formState.address.cidade}
                  onChange={(e) => handleNestedChange('address', 'cidade', e.target.value)}
                />
                <TextField
                  id="estado"
                  label="Estado"
                  variant="outlined"
                  fullWidth
                  value={formState.address.estado}
                  onChange={(e) => handleNestedChange('address', 'estado', e.target.value)}
                />
              </div>
              <div style={{ display: 'flex', gap: '1rem' }}>
                <TextField
                  id="complementoEndereco"
                  label="Complemento"
                  variant="outlined"
                  fullWidth
                  value={formState.address.complementoEndereco}
                  onChange={(e) => handleNestedChange('address', 'complementoEndereco', e.target.value)}
                />
                <TextField
                  id="numeroResidencial"
                  label="Número"
                  variant="outlined"
                  fullWidth
                  value={formState.address.numeroResidencial}
                  onChange={(e) => handleNestedChange('address', 'numeroResidencial', e.target.value)}
                />
              </div>

            </div>
          </div>
        </div>

        {/* Seção: Dados Empresariais */}
        <div className="form-consultores-section">
          <h3>Dados Empresariais</h3>
          <div className="form-consultores-section-pessoajuridica">
            <div className='form-consultores-section-pessoajuridica-left'>
              <TextField
                id="cnpj"
                label="CNPJ"
                variant="outlined"
                fullWidth
                value={formState.cnpj}
                onChange={handleCnpjChange}
              />
              <TextField
                id="razaoSocial"
                label="Razão Social"
                variant="outlined"
                fullWidth
                value={formState.razaoSocial}
                onChange={handleChange}
              />
              <TextField
                id="nomeFantasia"
                label="Nome Fantasia"
                variant="outlined"
                fullWidth
                value={formState.nomeFantasia}
                onChange={handleChange}
              />
              <TextField
                id="cepEmpresarial"
                label="CEP Empresarial"
                variant="outlined"
                fullWidth
                value={formState.addressPj.cepEmpresarial}
                onChange={(e) => handleNestedChange('addressPj', 'cepEmpresarial', e.target.value)}
              />
              <TextField
                id="enderecoEmpresarial"
                label="Endereço Empresarial"
                variant="outlined"
                fullWidth
                value={formState.addressPj.enderecoEmpresarial}
                onChange={(e) => handleNestedChange('addressPj', 'enderecoEmpresarial', e.target.value)}
              />
            </div>
            <div className='form-consultores-section-pessoajuridica-right'>
              <TextField
                id="numeroEmpresarial"
                label="Número Empresarial"
                variant="outlined"
                fullWidth
                value={formState.addressPj.numeroEmpresarial}
                onChange={(e) => handleNestedChange('addressPj', 'numeroEmpresarial', e.target.value)}
              />
              <TextField
                id="complementoEmpresarial"
                label="Complemento Empresarial"
                variant="outlined"
                fullWidth
                value={formState.addressPj.complementoEmpresarial}
                onChange={(e) => handleNestedChange('addressPj', 'complementoEmpresarial', e.target.value)}
              />
              <TextField
                id="bairroEmpresarial"
                label="Bairro Empresarial"
                variant="outlined"
                fullWidth
                value={formState.addressPj.bairroEmpresarial}
                onChange={(e) => handleNestedChange('addressPj', 'bairroEmpresarial', e.target.value)}
              />
              <TextField
                id="cidadeEmpresarial"
                label="Cidade Empresarial"
                variant="outlined"
                fullWidth
                value={formState.addressPj.cidadeEmpresarial}
                onChange={(e) => handleNestedChange('addressPj', 'cidadeEmpresarial', e.target.value)}
              />
              <TextField
                id="estadoEmpresarial"
                label="Estado Empresarial"
                variant="outlined"
                fullWidth
                value={formState.addressPj.estadoEmpresarial}
                onChange={(e) => handleNestedChange('addressPj', 'estadoEmpresarial', e.target.value)}
              />
            </div>
          </div>
        </div>

        {/* Seção: Dados Totvs */}
        <div className="form-consultores-section">
          <h3>Dados Totvs</h3>
          <FormControlLabel
            control={
              <Checkbox
                checked={formState.vinculadoTotvs}
                onChange={handleCheckboxChange}
                id="vinculadoTotvs"
              />
            }
            label="Está vinculado na Totvs"
          />
          <div className='form-consultores-section-dadostotvs'>
            <TextField
              id="codigoT"
              label="Código TOTVS"
              variant="outlined"
              fullWidth
              value={formState.codigoT}
              onChange={handleChange}
            />
            <TextField
              id="emailTotvs"
              label="E-mail Totvs Partner"
              variant="outlined"
              fullWidth
              value={formState.emailTotvs}
              onChange={handleChange}
            />
          </div>

          <div className='form-consultores-section'>
            <h3>Dados para acesso</h3>

            <div style={{ display: 'flex', gap: '1rem', paddingBottom: '1rem' }}>
              <TextField
                id="emailFas"
                label="Email FAS"
                variant="outlined"
                fullWidth
                value={formState.emailFas}
                onChange={handleChange}
                error={!!errors.emailFas}
                helperText={errors.emailFas}
              />
              <TextField
                id="confirmEmailFas"
                label="Confirmar Email FAS"
                variant="outlined"
                fullWidth
                value={confirmEmailFas}
                onChange={handleConfirmEmailChange}
                error={!!errors.confirmEmailFas}
                helperText={errors.confirmEmailFas}
              />
            </div>

            <div style={{ display: 'flex', gap: '1rem' }}>
              <TextField
                id="password"
                label="Senha"
                type="password"
                variant="outlined"
                fullWidth
                value={formState.password}
                onChange={handleChange}
                error={!!errors.password}
                helperText={errors.password}
              />
              <TextField
                id="confirmPassword"
                label="Confirmar Senha"
                type="password"
                variant="outlined"
                fullWidth
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
              />
            </div>
          </div>
        </div>

        {/* Botões de Ação */}
        <div className="form-consultores-actions">
          <button className='button-system voltar' onClick={handleBackNav}>
            Voltar
          </button>
          <button className='button-system cadastrar' onClick={handleSubmit}>
            Criar Consultor
          </button>
        </div>
      </div>

      {/* Modal de Feedback */}
      <ModalFeedback
        open={modalOpen}
        onClose={handleCloseModal}
        success={modalSuccess}
        message={modalMessage}
      />
    </div>
  );
};

export default FormConsultores;
