import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './views/Login/Login';

import './_globalStyles.scss';
import ConsultoresView from './views/ConsultoresView/ConsultoresView';
import Dashboard from './views/Dashboard/Dashboard';
import ListClients from './views/ListClients/ListClients';
import ListFeatures from './views/ListFeatures/ListFeatures';
import ListProjects from './views/ListProjects/ListProjects';
import RegisterClient from './views/RegisterClient/RegisterClient';
import RegisterFeatures from './views/RegisterFeatures/RegisterFeatures';
import RegisterProject from './views/RegisterProject/RegisterProject';
import ProtectedRoute from './components/ProtectedRoutes';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/registerconsultor" element={<ConsultoresView/>}/>

        <Route path="/dashboard" element={<ProtectedRoute element={Dashboard} />} />
        <Route path="/listclients" element={<ProtectedRoute element={ListClients} />} />
        <Route path="/registerclient" element={<ProtectedRoute element={RegisterClient} />} />
        <Route path="/listprojects" element={<ProtectedRoute element={ListProjects} />} />
        <Route path="/registerprojects" element={<ProtectedRoute element={RegisterProject} />} />
        <Route path="/listfeatures" element={<ProtectedRoute element={ListFeatures} />} />
        <Route path="/registerfeatures" element={<ProtectedRoute element={RegisterFeatures} />} />
        
      </Routes>
    </BrowserRouter>
  );
}

export default App;
