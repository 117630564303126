import React, { useState, useEffect } from 'react';
import { createConsultor } from '../../services/consultor.service';
import { fetchCnpjData } from '../../services/cnpj.service';
import { requestCEPInfos } from '../../services/viacep.service';
import { useNavigate } from 'react-router-dom';

interface Address {
  enderecoResidencial: string;
  numeroResidencial: string;
  complementoEndereco?: string;
  bairro: string;
  cidade: string;
  estado: string;
  cep: string;
}

interface AddressPj {
  cepEmpresarial: string;
  enderecoEmpresarial: string;
  complementoEmpresarial?: string;
  numeroEmpresarial: string;
  bairroEmpresarial: string;
  cidadeEmpresarial: string;
  estadoEmpresarial: string;
}

interface FormConsultorState {
  nomeCompleto: string;
  dataNascimento: string; // Formato 'dd/mm/yyyy'
  address: Address;
  cnpj: string;
  razaoSocial: string;
  nomeFantasia: string;
  addressPj: AddressPj;
  telefone: string;
  celular: string;
  emailParticular: string;
  vinculadoTotvs: boolean;
  codigoT: string;
  emailTotvs: string;
  emailFas: string;
  password: string;
}

interface ErrorsState {
  emailFas?: string;
  confirmEmailFas?: string;
  password?: string;
  confirmPassword?: string;
}

// Função para converter data de 'dd/mm/yyyy' para timestamp
const dateToTimestamp = (dateStr: string): number => {
  const [day, month, year] = dateStr.split('/').map(Number);
  return new Date(year, month - 1, day).getTime();
};

// Função para aplicar máscara de data
const applyDateMask = (value: string): string => {
  return value
    .replace(/\D/g, '') // Remove tudo que não é dígito
    .replace(/(\d{2})(\d)/, '$1/$2') // Adiciona a barra entre o dia e o mês
    .replace(/(\d{2})(\d)/, '$1/$2') // Adiciona a barra entre o mês e o ano
    .replace(/(\d{4})(\d)/, '$1'); // Limita o ano para 4 dígitos
};

const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const validatePassword = (password: string): boolean => {
  const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
  return passwordRegex.test(password);
};

const useFormConsultor = () => {
  const navigate = useNavigate();
  const [formState, setFormState] = useState<FormConsultorState>({
    nomeCompleto: '',
    dataNascimento: '',
    address: {
      enderecoResidencial: '',
      numeroResidencial: '',
      complementoEndereco: '',
      bairro: '',
      cidade: '',
      estado: '',
      cep: '',
    },
    cnpj: '',
    razaoSocial: '',
    nomeFantasia: '',
    addressPj: {
      cepEmpresarial: '',
      enderecoEmpresarial: '',
      complementoEmpresarial: '',
      numeroEmpresarial: '',
      bairroEmpresarial: '',
      cidadeEmpresarial: '',
      estadoEmpresarial: '',
    },
    telefone: '',
    celular: '',
    emailParticular: '',
    vinculadoTotvs: false,
    codigoT: '',
    emailTotvs: '',
    emailFas: '',
    password: '',
  });

  const [confirmEmailFas, setConfirmEmailFas] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState<ErrorsState>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    let formattedValue = value;

    if (id === 'dataNascimento') {
      formattedValue = applyDateMask(value);
    }

    setFormState((prevState) => ({
      ...prevState,
      [id]: formattedValue,
    }));
  };

  const handleConfirmEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmEmailFas(e.target.value);
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [id]: checked,
    }));
  };

  const handleNestedChange = <K extends keyof Address | keyof AddressPj>(
    section: 'address' | 'addressPj',
    key: K,
    value: string,
  ) => {
    setFormState((prevState) => ({
      ...prevState,
      [section]: {
        ...prevState[section],
        [key]: value,
      },
    }));
  };

  const handleCnpjChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const cnpj = e.target.value.replace(/\D/g, '');
    handleChange(e);
    if (cnpj.length === 14) {
      try {
        const cnpjData = await fetchCnpjData(cnpj);
        setFormState((prevState) => ({
          ...prevState,
          cnpj,
          razaoSocial: cnpjData.nome || '',
          nomeFantasia: cnpjData.fantasia || '',
          addressPj: {
            cepEmpresarial: cnpjData.cep || '',
            enderecoEmpresarial: cnpjData.logradouro || '',
            complementoEmpresarial: cnpjData.complemento || '',
            numeroEmpresarial: cnpjData.numero || '',
            bairroEmpresarial: cnpjData.bairro || '',
            cidadeEmpresarial: cnpjData.municipio || '',
            estadoEmpresarial: cnpjData.uf || '',
          },
        }));
      } catch (error) {
        console.error('Erro ao buscar dados do CNPJ:', error);
      }
    }
  };

  const handleCepChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const cep = e.target.value.replace(/\D/g, '');
    handleNestedChange('address', 'cep', cep);
    if (cep.length === 8) {
      try {
        const cepData = await requestCEPInfos(cep);
        setFormState((prevState) => ({
          ...prevState,
          address: {
            cep,
            enderecoResidencial: cepData?.logradouro || '',
            complementoEndereco: cepData?.complemento || '',
            bairro: cepData?.bairro || '',
            cidade: cepData?.localidade || '',
            estado: cepData?.uf || '',
            numeroResidencial: '',
          },
        }));
      } catch (error) {
        console.error('Erro ao buscar dados do CEP:', error);
      }
    }
  };

  useEffect(() => {
    const newErrors: ErrorsState = {};

    if (formState.emailFas && confirmEmailFas && formState.emailFas !== confirmEmailFas) {
      newErrors.emailFas = 'Os emails não coincidem.';
      newErrors.confirmEmailFas = 'Os emails não coincidem.';
    } else if (formState.emailFas && !validateEmail(formState.emailFas)) {
      newErrors.emailFas = 'Formato de email inválido.';
    }

    if (formState.password && confirmPassword && formState.password !== confirmPassword) {
      newErrors.password = 'As senhas não coincidem.';
      newErrors.confirmPassword = 'As senhas não coincidem.';
    } else if (formState.password && !validatePassword(formState.password)) {
      newErrors.password = 'A senha deve ter pelo menos 8 caracteres, incluindo uma letra maiúscula, um número e um caractere especial.';
    }

    setErrors(newErrors);
  }, [formState.emailFas, confirmEmailFas, formState.password, confirmPassword]);

  const validateForm = () => {
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }

    try {
      await createConsultor({
        ...formState,
        dataNascimento: dateToTimestamp(formState.dataNascimento),
      });
      setModalSuccess(true);
      setModalMessage('Consultor criado com sucesso!');
    } catch (error) {
      setModalSuccess(false);
      setModalMessage('Erro ao criar consultor. Por favor, tente novamente.');
    } finally {
      setModalOpen(true);
    }
  };

  const handleBackNav = () => {
    navigate('/');
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    if (modalSuccess) {
      navigate('/');
    }
  };

  return {
    formState,
    confirmEmailFas,
    confirmPassword,
    errors,
    modalOpen,
    modalSuccess,
    modalMessage,
    handleChange,
    handleConfirmEmailChange,
    handleConfirmPasswordChange,
    handleNestedChange,
    handleCheckboxChange,
    handleCnpjChange,
    handleCepChange,
    handleSubmit,
    handleBackNav,
    handleCloseModal,
  };
};

export default useFormConsultor;
