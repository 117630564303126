// src/services/client.service.ts
import axios from 'axios';
import { FormClientState } from '../components/FormClient/useFormClient';


const API_URL = process.env.REACT_APP_URL_CLIENTS_URL as string;

export const createClient = async (clientData: FormClientState) => {
  try {
    const response = await axios.post(API_URL, clientData);
    return response.data;
  } catch (error) {
    console.error('Error creating client:', error);
    throw error;
  }
};

export const getClients = async () => {
  try {
    const response = await axios.get(API_URL);
    return response.data;
  } catch (error) {
    console.error('Error fetching clients:', error);
    throw error;
  }
};