import axios from 'axios';

// Determine a URL base com base no ambiente
const API_URL = process.env.REACT_APP_URL_SIGNIN_URL as string
// const API_URL ='http://localhost:3001/auth'


export const login = async (email: string, password: string) => {
  try {
    const response = await axios.post(`${API_URL}/login`, { email, password });

    // Verifica se o token e o nome do usuário estão presentes na resposta
    if (response.data.access_token && response.data.userName) {
      localStorage.setItem('token', response.data.access_token);
      localStorage.setItem('userName', response.data.userName);
    } else {
      throw new Error('Falha ao salvar dados da autenticação');
    }

    return response.data;
  } catch (error) {
    throw new Error('Falha na autenticação. Verifique suas credenciais e tente novamente.');
  }
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userName');
};

export const getToken = () => {
  return localStorage.getItem('token');
};

export const getUserName = () => {
  return localStorage.getItem('userName');
};

export const isAuthenticated = () => {
  return !!getToken(); // Retorna true se o token existir no localStorage
};
