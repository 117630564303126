import LayoutDashboard from "../../layouts/LayoutDashboard/LayoutDashboard";

export default function Dashboard() {
  return (
    <LayoutDashboard>
      <div className="dashboard-container">
        <h2>this is dashboard</h2>
      </div>
    </LayoutDashboard>
  )
}