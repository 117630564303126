// src/utils/auth.ts
export const isAuthenticated = (): boolean => {
  const token = localStorage.getItem('token');
  return !!token;
};

export const getUserName = (): string | null => {
  return localStorage.getItem('userName');
};

export const login = (token: string, userName: string) => {
  localStorage.setItem('token', token);
  localStorage.setItem('userName', userName);
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userName');
};
