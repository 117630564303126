// src/hooks/useListClients.ts
import { useEffect, useState } from 'react';
import { getClients } from '../../services/client.service';


interface Client {
  id: number;
  cnpj: string;
  razaoSocial: string;
  nomeFantasia: string;
  telefone: string;
  celular: string;
  responsaveis: {
    nome: string;
    email: string;
  }[];
}

const useListClients = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const data = await getClients();
        setClients(data);
      } catch (error) {
        setError('Failed to fetch clients');
      } finally {
        setLoading(false);
      }
    };

    fetchClients();
  }, []);

  return { clients, loading, error };
};

export default useListClients;
