import axios from 'axios';

const API_URL = process.env.REACT_APP_URL_BASEURL_API as string;

// const API_URL ='http://localhost:3001/'

export const fetchCnpjData = async (cnpj: string) => {
  try {
    const response = await axios.get(`${API_URL}cnpj/buscar?cnpj=${cnpj}`);
    return response.data;
  } catch (error) {
    console.error('Erro ao buscar dados do CNPJ:', error);
    throw error;
  }
};
