import FormFeatures from "../../components/FormFeatures/FormFeatures";
import LayoutDashboard from "../../layouts/LayoutDashboard/LayoutDashboard";

export default function RegisterFeatures() {
  return (
    <LayoutDashboard>
      <div className="register-features-page-form">
        <FormFeatures />
      </div>
    </LayoutDashboard>
  )
}