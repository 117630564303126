import { TextField, Button, Alert } from '@mui/material';
import { useLogin } from './useLogin'; // Importe o hook customizado
import './_login.scss';

export default function Login() {
  const {
    username,
    setUsername,
    password,
    setPassword,
    error,
    handleLogin,
  } = useLogin();

  return (
    <div className='login-view-container'>
      <div className='login-view-content'>
        <div className='login-view-content-title'>
          <h2>Fas Solutions</h2>
          <p>Portal de gestão de projetos</p>
        </div>

        <div className='login-view-content-form'>
          <TextField
            id="username"
            label="Usuário"
            variant="outlined"
            sx={{ width: '100%' }}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />

          <TextField
            id="password"
            label="Senha"
            variant="outlined"
            type="password"
            sx={{ width: '100%' }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>

        {error && <Alert severity="error">{error}</Alert>}

        <div className='register-container'>
          <p>Não possui cadastro?</p>
          <a href="/registerconsultor">
            Clique aqui para realizar seu cadastro
          </a>
        </div>

        <div className='login-view-content-footer'>
          <Button
            onClick={handleLogin}
            className='button-system login'
            variant="contained"
            color="primary"
            sx={{ width: '100%' }}
          >
            Login
          </Button>
        </div>
      </div>
    </div>
  );
}
