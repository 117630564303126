import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

interface ModalFeedbackProps {
  open: boolean;
  onClose: () => void;
  success: boolean;
  message: string;
}

const ModalFeedback: React.FC<ModalFeedbackProps> = ({ open, onClose, success, message }) => {
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-feedback-title" aria-describedby="modal-feedback-description">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          textAlign: 'center',
        }}
      >
        {success ? (
          <CheckCircleIcon sx={{ color: 'green', fontSize: 60, mb: 2 }} />
        ) : (
          <ErrorIcon sx={{ color: 'red', fontSize: 60, mb: 2 }} />
        )}
        <Typography id="modal-feedback-title" variant="h6" component="h2">
          {success ? 'Sucesso' : 'Falha'}
        </Typography>
        <Typography id="modal-feedback-description" sx={{ mt: 2, mb: 3 }}>
          {message}
        </Typography>
        <Button variant="contained" onClick={onClose}>
          Fechar
        </Button>
      </Box>
    </Modal>
  );
};

export default ModalFeedback;
